import './components/aiSelect.js';
import { contactSubmit } from './contact.js';
import FilterUnits from './services/FilterUnits.js';
import Slider from "./services/Slider.js";

if (document.getElementById('contact_form')) {
	contactSubmit();
}


document.addEventListener('DOMContentLoaded', () => {
	new Slider('[data-slider="facility-images"]');
	new Slider('[data-slider="reviews"]');
	new FilterUnits('[data-js="facility-rates-node"]');

	// Main - Toggle Hero blocks
	(() => {
		const toggleBlocks = [...document.querySelectorAll('[data-js="toggle-block"]')];

		if (!toggleBlocks.length) {
			return;
		}

		toggleBlocks.forEach(toggle => {
			toggle.addEventListener('click', () => {
				toggleBlocks.forEach(toggle => toggle.classList.remove('active'));
				toggle.classList.add('active');
			});
		});
	})();
}, true);

document.addEventListener('DOMContentLoaded', () => {

	// eslint-disable-next-line no-new
	new Slider('[data-slider="about-slider"]');

	// Mobile menu
	let menuIcon = document.querySelector(".mobile-btn-wrap");
	let mobileMenu = document.querySelector(".mobile-menu");


	menuIcon.addEventListener("click", function () {
		mobileMenu.classList.toggle("active");
		menuIcon.classList.toggle("active");
	});

	// About accordion
	let d = document;
	let ul = d.querySelector('.according-list');
	let h4s = d.querySelectorAll('.according-list h2');

	if (ul) {
		ul.addEventListener('click', function(e) {
			if (e.target && e.target.nodeName === 'H2') {
				h4s.forEach(h4 => h4.classList.remove('active'));				
				e.target.classList.add('active');
			} else if (e.target && e.target.nodeName === 'IMG') {
				h4s.forEach(h4 => h4.classList.remove('active'));	
				console.log(e.target.parentNode);
				e.target.parentNode.classList.add('active');
			}
		});
	}

}, true);

